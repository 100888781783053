import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'

const ProtectedRoute = () => {
    const userInfo = !false;

    const user = JSON.parse(localStorage.getItem('login'))

    // show unauthorized screen if no user is found in redux store
    if (!user) {
        window.location.href = '/login';
        // return (
        //     <div className='unauthorized'>
        //         <h1>Unauthorized :(</h1>
        //         <span>
        //             <NavLink to='/login'>Login</NavLink> to gain access
        //         </span>
        //     </div>
        // )
    }

    return <Outlet />
}

export default ProtectedRoute