import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';

import axios from 'axios';
import './App.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import { loginSuccess, loginFailure } from './actions';


const App = () => {
    const [instrukturData, setInstrukturData] = useState([]);
    const [selectedInstruktur, setSelectedInstruktur] = useState(null);
    const [loginStatus, setLoginStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Dapatkan fungsi dispatch dari Redux

    const onFinish = async (values) => {
        try {
          setLoading(true);
          const response = await axios.post('https://api.pspp-integrated.com/api/v1/instruktur/InstrukturController/login', {
            id: values.username,
            password: values.password,
          });
    
          if (response.data.status !== 0) {
            message.success('Login berhasil!');
            localStorage.setItem('login', JSON.stringify(response.data.data));
            navigate('/');
          } else {
            message.error('Login gagal. Periksa kembali username dan password Anda.');
          }
        } catch (error) {
          console.error('Error fetching login data:', error);
          message.error('Terjadi kesalahan saat menghubungi server. Silakan coba lagi.');
        } finally {
          setLoading(false);
        }
      };
    

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleInstrukturChange = (value) => {
        setSelectedInstruktur(value);
    };

    useEffect(() => {
        // Fungsi untuk memuat data instruktur dari API
        const fetchInstrukturData = async () => {
            try {
                const response = await axios.get('https://api.pspp-integrated.com/api/v1/instruktur/InstrukturController/get');
                setInstrukturData(response.data);
            } catch (error) {
                console.error('Error fetching instruktur data:', error);
            }
        };

        fetchInstrukturData();
    }, []);


    return (
        <div className="login-container">
            <h1>Login</h1>
            <Form
                name="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                {/* Select Box Instruktur */}
                <Form.Item
                    label="Instruktur"
                    name="username"
                    rules={[{ required: true, message: 'Instruktur!' }]}
                >
                    <Select onChange={handleInstrukturChange} value={selectedInstruktur}>
                        {instrukturData.map((instruktur) => (
                            <Select.Option key={instruktur.id} value={instruktur.id}>
                                ({instruktur.kampus}) {instruktur.nama}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Input Password */}
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Masukkan password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Masuk
                    </Button>
                </Form.Item>
            </Form>
        </div>

    );
};

export default App;
