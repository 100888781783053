// redux-persist-config.js
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Menggunakan localStorage sebagai penyimpanan

import rootReducer from './reducers'; // Impor reducer Anda

const persistConfig = {
  key: 'root', // Kunci penyimpanan
  storage, // Penyimpanan lokal (localStorage)
  // Anda dapat menambahkan opsi konfigurasi lainnya di sini
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export { persistedReducer };
