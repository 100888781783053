// src/Home.js
import React from 'react';
import { Row, Col } from 'antd';
import TableExample from '../components/TableExample';

import './Home.scss';

const Home = () => {

  const user = JSON.parse(localStorage.getItem('login'));

  const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  return (
    <Row justify="center">
      <Col xs={24} sm={24} md={24} lg={20} xl={20}>
        <div className="container">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1>Jadwal Mengajar</h1>
            <h3>{user.nama} - <span style={{ cursor: 'pointer' }} onClick={logout}>Logout</span></h3>
          </div>
          <TableExample />
        </div>
      </Col>
    </Row>
  );
};

export default Home;
