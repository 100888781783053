import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import axios from 'axios';
import './Table.css'
import { Modal, Form, Input, Button, Select, Row, Col } from 'antd';

const { Option } = Select;

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: 'Hari',
    dataIndex: 'hari',
    key: 'hari',
  },
  {
    title: 'Tanggal',
    dataIndex: 'tgl_mengajar',
    key: 'tgl_mengajar',
  },
  {
    title: 'Jam Mulai',
    dataIndex: 'waktu_mulai',
    key: 'waktu_mulai',
  },
  {
    title: 'Jam Selesai',
    dataIndex: 'waktu_selesai',
    key: 'waktu_selesai',
  },
  {
    title: 'Materi',
    dataIndex: 'mata_diklat',
    key: 'mata_diklat',
  },
  {
    title: 'Jumlah Sesi',
    dataIndex: 'jumlah_sesi',
    key: 'jumlah_sesi',
  },
  {
    title: 'Kelas',
    dataIndex: 'kelas',
    key: 'kelas',
  },
  {
    title: 'Ruangan',
    dataIndex: 'ruangan',
    key: 'ruangan',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];


const TableExample = () => {

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [forms, setForms] = useState([]);
  const [visible, setVisible] = useState(false);

  const handleRowClick = (item) => {
    setSelected(item);

    const forms = [];
    for (let i = 0; i < parseInt(item.jumlah_sesi); i++) {
      forms.push(
        <div key={i}>
          <h3>Soal {i + 1}</h3>
          <Form.Item
            label="Pertanyaan"
            name={`soal-${i}`}
            rules={[
              {
                required: true,
                message: 'Harus diisi',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Pilihan A"
            name={`pilihan_a-${i}`}
            rules={[
              {
                required: true,
                message: 'Harus diisi',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Pilihan B"
            name={`pilihan_b-${i}`}
            rules={[
              {
                required: true,
                message: 'Harus diisi',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Pilihan C"
            name={`pilihan_c-${i}`}
            rules={[
              {
                required: true,
                message: 'Harus diisi',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Pilihan D"
            name={`pilihan_d-${i}`}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Pilihan E"
            name={`pilihan_e-${i}`}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Jawaban Benar"
            name={`jawaban-${i}`}
            rules={[
              {
                required: true,
                message: 'Harus diisi',
              },
            ]}
          >
            <Select>
              <Option value="A">A</Option>
              <Option value="B">B</Option>
              <Option value="C">C</Option>
              <Option value="D">D</Option>
              <Option value="E">E</Option>
            </Select>
          </Form.Item>
        </div>
      )
    }

    setForms(forms);
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
  };

  const onFinish = async (values) => {
    const payload = convertToObject(values);
    console.log('Form values:', payload);

    await axios.post(`https://api.pspp-integrated.com/api/v1/instruktur/KehadiranController/absenSoal`, payload);

    window.location.reload();

    // Lakukan aksi simpan data atau apa pun yang diperlukan di sini
    // handleHideModal();
  };

  const convertToObject = (data) => {
    const result = {};

    // Iterate through the original data object
    for (const key in data) {
      // Split the key based on the underscore character
      const [field, index] = key.split('-');
      const dataIndex = parseInt(index);

      // Check if the key contains an index at the end
      if (!isNaN(dataIndex)) {
        // Create a new object if the index doesn't exist in the result object
        if (!result[dataIndex]) {
          result[dataIndex] = { id_jadwal: selected.id, id_instruktur: selected.id_instruktur };
        }

        // Update the corresponding field in the object
        result[dataIndex][field] = data[key];
      }
    }

    // Convert the result object to an array of objects
    const formattedData = Object.values(result);
    return formattedData;
  };


  useEffect(() => {
    const fetchData = async () => {
      const user = JSON.parse(localStorage.getItem('login'));
      const { data } = await axios.get('https://api.pspp-integrated.com/api/v1/kbm/JadwalKbm/getByInstruktur?id_instruktur=' + user.id)
      data.data.forEach((item, index) => {
        item.no = ++index;
        item.tgl_mengajar = moment(item.tgl_mengajar).format('DD-MM-YYYY')
        item.status = item.soal ? 'Sudah Absen' : 'Belum Absen'
      });
      setData(data.data);
    }

    fetchData();
  }, []);

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />

      <Modal
        title={`Input ${selected.jumlah_sesi} Soal`}
        open={visible}
        onCancel={handleHideModal}
        footer={null}
      >
        <Form
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >

          {forms}


          <Button type="primary" htmlType="submit" style={{ marginLeft: '120px', marginRight: '10px' }}>
            Simpan
          </Button>
          <Button htmlType="button" onClick={handleHideModal}>
            Batal
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default TableExample;
